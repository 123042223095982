import React from 'react'
import { graphql } from "gatsby"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import MerciP from '../containers/merci'

const Merci = ({data}) => {
    const url = data.site.siteMetadata.siteUrl;
    const description = `Merci d'avoir prit contact avec nous pour la réalisation de votre projet de piscine et de jardin.`;

    return (
        <Layout url={url} headerStyle="darkHeader">
            <Seo title="Page de remerciement" description={description}/>
            <MerciP/>
        </Layout>
    )
}
export default Merci;

export const query = graphql`
query PageMerciQuery {
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
