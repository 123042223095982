import React from 'react'
import {Link} from 'gatsby'
import {MerciWrap} from './merci.stc.js'

const Merci = () => {
    return (
        <MerciWrap>
            <div className="row justify-content-center align-content-center">
                <h1>Votre message à bien été envoyé!</h1>
                <h2>Merci de nous avoir contacté! Nous reviendrons rapidement vers vous</h2>
                <Link to="/">Retour à l'accueil</Link>
            </div>
        </MerciWrap>
    )
}

export default Merci;